.video-thumbnail-container {
    position: relative;
    display: inline-block;

    img {
        display: block;
        width: 100%;
        min-height: 400px;
    }




}

.aspect-ratio-box {
    position: relative;
    width: 100%;
    /* or any desired width */
    // padding-bottom: 177%;
    padding-bottom: calc(3/4 * 100%);
    /* 16:9 aspect ratio (9 / 16 * 100) */
    /* Just for demonstration */
}

.aspect-ratio-box-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Just for demonstration */
}




.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    /* Makes sure the icon doesn't block clicks */

    /* Circle background */
    background-color: rgba(0, 0, 0, 0.5);
    /* Transparent black */
    border-radius: 50%;
    width: 30px;
    /* Adjust size as needed */
    height: 30px;
    /* Adjust size as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    color: white;
}




.select-icon {
    display: none;
    font-size: 12px;
    color: var(--bs-primary);
    background-color: var(--bs-primary-light);
    border-radius: 5px 0 5px 0;
    padding: 4px 7px;
    z-index: 9999;
}

.clear-icon {
    color: white;
    width: 18px;
    height: 18px;
    background-color: var(--bs-primary);
    border-radius: 5px 0 8px 0;
    transition: 0.15s all ease-out;
}

.card:hover .select-icon {
    display: block;
}

@mixin clear-icon {
    width: 25px;
    height: 25px;
    line-height: 25px;

    i {
        font-size: 16px;
    }
}

.card:hover .clear-icon {
    @include clear-icon;
}

@media screen and (max-width: 480px) {
    .clear-icon {
        @include clear-icon;
    }
}


.faded::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Black background with 50% opacity */
    pointer-events: none;
    /* Keeps the overlay non-interactive */
    z-index: 2;
    /* Ensure the overlay stays above other content */
    border-radius: 5px;

}


.loader {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite
}

.loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 2px solid #FFF;
    animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg)
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
    }

    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
    }

    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
    }
}