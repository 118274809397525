.p-dialog .p-dialog-header {
    padding: 7px;
}

@media only screen and (min-width: 768px) {
    .p-dialog .p-dialog-header {
        top: 1px;
        position: relative;

        // this ro remove visible border on mobile window header 
    }
}


.p-dialog .p-dialog-content {
    padding: 0;
}

.p-confirm-dialog .p-dialog-content {
    padding: 7px;
}

.modal-header {
    padding: 0 10px 10px;
}

.modal-body {
    min-height: 200px;
    width: calc(100vw - 50px);
    max-width: 600px;

    &.modal-md {
        min-height: 500px;
        width: calc(100vw - 50px);
        max-width: 750px;

    }

    &.modal-lg {
        height: 55vh;
        width: calc(100vw - 50px);
        max-width: 1000px;

    }

    &.modal-xxl {
        height: 70vh;
        width: calc(100vw - 50px);
        max-width: 1330px;

    }

    &.full-width {
        height: 75vh;
        width: calc(100vw - 50px);
        max-width: 1500px;

    }
}