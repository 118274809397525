.ql-editor {
    background-color: transparent !important;
    color: #000000 !important;
}

.ql-font-arial {
    font-family: 'Arial', sans-serif;
}

.ql-font-roboto {
    font-family: 'Roboto', monospace;
}

.ql-font-inter {
    font-family: 'Inter', serif;
}

.ql-font-verdana {
    font-family: 'Verdana', Geneva, sans-serif;
}

.ql-font span[data-value="arial"]::before {
    font-family: "Arial";
}

.ql-font span[data-value="verdana"]::before {
    font-family: "Verdana";
}

.ql-font span[data-value="roboto"]::before {
    font-family: "Roboto";
}

.ql-font span[data-value="inter"]::before {
    font-family: "Inter";
}

/*****/

.ql-bg-red {
    text-shadow: 2px 2px #ff0000;
}

.ql-bg-green {
    text-shadow: 2px 2px green;
}

.ql-bg-blue {
    background-color: blue;
}

.ql-container .ql-editor .ql-background-white {
    background-color: white !important;
    /* Ensure white background is applied */
}

.default-bg {
    canvas {
        background: var(--bs-dark-light) !important;
    }

    [data-bs-theme="dark"] {
        canvas {
            background: var(--bs-light) !important;
        }
    }
}