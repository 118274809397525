/* body {
  margin: 0;
  overflow: hidden;
} */
#canvases canvas {
  position: fixed;
  top: 0px;
  left: 0px;
}
#handTrackerCanvas {
  z-index: 1;
}
#ARCanvas {
  z-index: 2;
}
canvas {
  /* mirror the canvas - useful if camera is in selfie mode only: */
  /*transform: rotateY(180deg);*/
}
