.tooltip {
    z-index: 99999;
}

.p-dropdown-items {
    padding-left: 5px;
}

.p-tieredmenu-root-list {
    padding: 0;
}

.p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
    background: #fff !important;
}

.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-up {
    border-radius: 6px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding: 0;
}

.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-down {
    border-radius: 6px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    padding: 0;
}

.p-column-header-content {
    color: var(--bs-secondary-color);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.95rem;

}

.p-button-danger {
    background-color: var(--bs-danger);
    color: white;
}

.p-menu-list,
.p-reset {
    padding: 0 !important;
}

.p-menuitem {
    margin: 0;
}

.p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
    background: none !important;
}

[data-bs-theme="dark"] {

    .p-dialog-content,
    .p-dialog-header,
    .p-dialog-footer {
        background: var(--bs-body-bg) !important;
    }

    .block-ui-message-container {
        background: var(--kt-card-bg);
    }

    .block-ui-message {
        color: var(--bluegray-100);
    }

    .p-datatable .p-datatable-thead>tr>th {
        background: var(--kt-card-bg);
        color: var(--gray-300);

    }

    .p-datatable .p-datatable-tbody>tr>td {
        border-color: var(--gray-900);
    }

    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        background: var(--kt-card-bg);
        color: white;
    }

    .p-dropdown {
        background: var(--kt-card-bg);

    }

    .p-fileupload .p-fileupload-content,
    .p-fileupload-buttonbar {
        background: var(--kt-card-bg);
        border-color: var(--gray-700);
    }


    // accordion
    // .p-accordion-header-link,
    // .p-accordion-content,
    // .p-component,
    // .p-datepicker-header {
    //     background: var(--kt-card-bg) !important;
    //     color: white !important;
    //     border-color: var(--gray-700) !important;


    // }

    // .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    //     color: #9993dd !important;
    //     background: #1f263f !important;
    // }

    button.p-highlight.p-link.p-paginator-element.p-paginator-page.p-paginator-page-end.p-paginator-page-start {
        background: var(--bs-light);
    }

    .p-dropdown {
        background: var(--bs-light);
    }

    .alert-info .alert-text {

        color: #9b6ff7;
    }
}

.p-fileupload .p-fileupload-row>div {
    padding: 0;
}

.p-fileupload-row {
    padding: 5px 0px;
    border-bottom: 1px solid var(--gray-200);

    .p-button {
        padding: 3px;
        width: 34px;
    }

}

.custom-solid-form-control {
    background-color: var(--kt-input-solid-bg);
    border-color: var(--kt-input-solid-bg);
    color: var(--kt-input-solid-color);

}

.p-sortable-column-icon {
    color: #b4b4b4 !important;
}



.p-image-toolbar button.p-image-action.p-link {
    background: #0000002e;
    z-index: 999;
}

.p-dropdown-item {
    margin: 0 !important;
}

// .p-datatable .p-datatable-tbody>tr {
//     background: var(--bs-card-bg) !important;

// }

// .p-dropdown-panel,
// .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
//     background: var(--bs-card-bg) !important;

// }3


.p-inputswitch.p-highlight .p-inputswitch-slider {
    background: #3b82f6 !important;
}



.block-ui-overlay {
    visibility: hidden;
    // opacity: 0.8;
}

.block-ui-message-container {
    width: fit-content !important;
    margin: 0 auto !important;
    padding: 15px 25px !important;
    border-radius: 12px !important;
    box-shadow: 0px 0px 9px 4px #76767617 !important;
    background: #fff;
}


body[data-kt-aside-minimize="on"] .header-brand {
    // background: white !important;
    width: auto;

    a {
        margin-right: 10px;
    }

}

.p-button {
    padding: 7px 10px !important;
}

// to move sidebar to right
.drawer.drawer-start {
    left: unset !important;
    right: 0 !important;
}

.drawer.drawer-start:not(.drawer-on) {
    transform: translateX(100%) !important;
}