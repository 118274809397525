.try-it-link {
    background: rgb(83, 122, 252);
    color: white;
    padding: 10px 15px;
    border-radius: 13px;
    text-decoration: none;
    width: fit-content;

    svg {
        padding-right: 5px
    }
}