.square {
    width: 20px;
    height: 20px;
    border: 2px solid var(--bs-primary);
    border-radius: 3px;
    background-color: transparent;
}

.landscape-rectangle {
    width: 30px;
    height: 20px;
    border: 2px solid var(--bs-primary);
    border-radius: 3px;
    background-color: transparent;
}

.portrait-rectangle {
    width: 20px;
    height: 30px;
    border: 2px solid var(--bs-primary);
    border-radius: 3px;
    background-color: transparent;
}