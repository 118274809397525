.capitalized {
    text-transform: capitalize;
}

.shadow-primary {
    box-shadow: 0 0 12px -1px rgb(0 158 247) !important;
}

@media (min-width: 1600px) {

    .col-xxxl-2 {
        flex: 0 0 auto;
        width: 20%;
    }
}

@media screen and (max-width: 480px) {
    .show-mobile {
        display: block !important;
    }
}

.min-h-400px {
    min-height: 400px;
}

.center-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.stroke-primary {
    stroke: var(--bs-text-primary);
    fill: var(--bs-text-primary);
}

.stroke-secondary {
    stroke: var(--bs-gray-400);
    fill: var(--bs-gray-400);
}

.gradient-background {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
}

.gradient-background-reverse {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
}

.gradient-background-hover,
.gradient-background-reverse-hover {
    transition: all 0.3s ease, visibility 0.3s ease;

}




.hover-bg-black:hover {
    background: rgba(0, 0, 0, 0.2);
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.hover-container {
    position: relative;
}

@media screen and (min-width: 768px) {
    .visible-on-hover {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
    }
}


.hover-container:hover .visible-on-hover {
    opacity: 1;
    visibility: visible;
}

.hover-container:hover .gradient-background-hover {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0));
}

.hover-container:hover .gradient-background-reverse-hover {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0));
}


.z-index-999 {
    z-index: 999;

}

.z-far {
    z-index: 9999;
}

.text-shadow {
    text-shadow: 2px 1px 2px black;
}

.force-transparent-bg {
    background: transparent !important;
}