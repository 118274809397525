.editor-fields {
    .slider-handle {
        position: absolute;
        top: -15px;
        /* Adjust as necessary */
        transform: translateX(-50%);
        // background-color: #007ad9;
        // color: white;
        border-radius: 50%;
        // padding: 5px 5px;
        width: 30px;
        height: 30px;
        font-size: 11px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        background: white;
        border: 2px solid #007ad9;
        color: #007ad9;
    }

    .p-slider .p-slider-handle {
        opacity: 0;
        width: 40px;
        height: 20px;
        border-radius: 0;
        background: red;
        transform: translate(-50%, -50%);

        /* Hide the default handle */
    }
}